import {Descriptions, Grid, Space} from 'antd';
import React from 'react';
import {IUser} from '../../../interfaces';

const {useBreakpoint} = Grid;

type Props = {
  user?: IUser;
};

export const ShowUser: React.FC<Props> = (props) => {
  const {user} = props;

  const {xl, xs} = useBreakpoint();

  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
        textAlign: xl ? 'unset' : 'center',
      }}
    >
      {user && (
        <Descriptions column={xs ? 1 : 2}>
          {/*<Descriptions.Item label="ID">{address.id}</Descriptions.Item>*/}
          {'firstName' in user && (
            <Descriptions.Item label="First name">
              {user.firstName}
            </Descriptions.Item>
          )}
          {'lastName' in user && (
            <Descriptions.Item label="Last name">
              {user.lastName}
            </Descriptions.Item>
          )}
          {'email' in user && (
            <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
          )}
          {'id' in user && 'altUserId' in user && (
            <Descriptions.Item label="User ID">
              {user.id} (alt: {user.altUserId})
            </Descriptions.Item>
          )}
        </Descriptions>
      )}
    </Space>
  );
};
