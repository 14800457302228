import {AxiosInstance} from 'axios';
import {vars} from '../../vars';
import {TOKEN_KEY} from '../../authProvider';
import {IOrder, IOrderLogisticType} from '../../interfaces';
import {SaveShippingAddressRequest} from 'modules/order-shipping-address/dataProvider';

type SaveOrderItemRequest = {
  name: string;
  url: string;
  quantity: number;
  price: number;
  detail?: string;
  weight?: number;
  // originalPackaging: boolean;
  categoryId: string;
  subCategoryId?: string;
  imageUrl?: string;
  invoiceData: {
    commission: number;
    customsDuties: number;
    warranty: number;
    qempo: number;
  };
};

type SaveOrderRequest = {
  orderId?: string;
  purchaserId: string;
  courierId?: string;
  invoiceData: {
    coupon?: number;
    couponName?: string;
    lastMile: number;
  };
  serviceType: string;
  orderItems: SaveOrderItemRequest[];
  shippingAddressId: string;
  shippingAddress: SaveShippingAddressRequest;
  beforeDate: string;
};

export class OrderService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public async saveOrder(request: SaveOrderRequest) {
    let order: IOrder;
    if (request.serviceType == 'LITE') {
      const url = `${vars.baseApiUrl}/logistics/admin/order/lite/paid`;

      const body = {
        purchaserId: request.purchaserId,
        invoiceData: request.invoiceData,
        items: request.orderItems,
        shippingAddressId: request.shippingAddressId,
        shippingAddress: request.shippingAddress,
        courierId: request.courierId,
        beforeDate: request.beforeDate,
      };

      const {data} = await this.httpClient.post(url, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      });

      order = data;
    } else {
      const url = `${vars.baseApiUrl}/logistics/admin/order/full/paid`;

      const body = {};

      const {data} = await this.httpClient.post(url, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      });

      order = data;
    }

    return order;
  }

  public async getOrder(orderId: string) {
    const url = `${vars.baseApiUrl}/logistics/admin/order/${orderId}`;
    const {data} = await this.httpClient.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return data;
  }

  public async getOrders(
    page: number,
    size: number,
    orderId?: string,
    purchaserId?: string,
    states: string[] = [],
    paidAt: [string, string] | [] = []
  ) {
    const query = new URLSearchParams();
    query.set('page', `${page}`);
    query.set('size', `${size}`);
    if (orderId) query.set('order_id', orderId);
    if (purchaserId) query.set('purchaser_id', purchaserId);
    if (states) {
      states.forEach((s) => query.append('state', s));
    }
    if (paidAt[0]) query.set('paid_at_gte', paidAt[0]);
    if (paidAt[1]) query.set('paid_at_lte', paidAt[1]);

    const url = `${vars.baseApiUrl}/logistics/admin/order?${query.toString()}`;
    const {data} = await this.httpClient.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return data;
  }

  public static canCancelOrder(order: IOrder): boolean {
    return ['CREATED', 'PAID', 'COMPLETED'].includes(order.state);
  }

  public async cancelOrder(orderId: string) {
    const url = `${vars.baseApiUrl}/logistics/admin/order/${orderId}/cancel`;
    const {data} = await this.httpClient.post(`${url}`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return data;
  }

  public async cancelItems(orderId: string, orderItemIds: string[]) {
    const url = `${vars.baseApiUrl}/logistics/admin/order/${orderId}/item/cancel`;
    const {data} = await this.httpClient.post(
      `${url}`,
      {
        orderItemIds,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      }
    );

    return data;
  }

  public static nextProgressOrderItemState(
    currentState: string
  ): string | undefined {
    if (currentState === 'ITEM_CREATED') return 'PURCHASED';
    if (currentState === 'PURCHASED') return 'RECEIVED';
    if (currentState === 'RECEIVED') return 'ARRIVED';
    if (currentState === 'ARRIVED') return 'DELIVERED';
    if (currentState === 'DELIVERED') return 'COMPLETED';
    return undefined;
  }

  public async progressItems(
    orderId: string,
    orderItemIds: string[],
    targetState: string
  ) {
    const url = `${vars.baseApiUrl}/logistics/admin/order/${orderId}/item/progress`;
    const {data} = await this.httpClient.post(
      `${url}`,
      {
        orderItemIds,
        targetState,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      }
    );

    return data;
  }

  public async setLogisticType(
    orderId: string,
    logisticType: IOrderLogisticType,
    id: string
  ) {
    const url = `${vars.baseApiUrl}/logistics/admin/order/${orderId}/logistic-type`;

    const body: Record<string, string> = {logisticType};

    if (logisticType === 'FLIGHT') body.flightId = id;
    if (logisticType === 'COURIER') body.courierId = id;

    const {data} = await this.httpClient.post(`${url}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return data;
  }

  public async setNotes(orderId: string, notes: string) {
    const url = `${vars.baseApiUrl}/logistics/admin/order/${orderId}/notes`;

    const {data} = await this.httpClient.post(
      `${url}`,
      {
        notes,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      }
    );

    return data;
  }
}
