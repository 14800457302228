import React, {useMemo} from 'react';
import {IResourceComponentsProps, useShow} from '@refinedev/core';
import {Col, Row, Space} from 'antd';
import {IOrder} from '../../../interfaces';
import {
  InvoiceLines,
  Payments,
  Invoice,
  OrderInfo,
  OrderProducts,
  OrderLogistic,
} from '../components';
import {OrderShippingAddressEdit} from '../../order-shipping-address/components';
import {useParams} from 'react-router-dom';
import {OrderNotes} from '../components/order-notes';
import {OrderPurchaser} from '../components/order-purchaser';

export const OrderShow: React.FC<IResourceComponentsProps> = () => {
  const {id} = useParams();
  const {queryResult: orderQueryResult} = useShow<IOrder>({
    resource: 'orders',
    id,
  });

  const {
    data,
    isLoading: isLoadingFetch,
    isFetching,
    isRefetching,
    isInitialLoading,
    refetch,
  } = orderQueryResult;
  const order: IOrder = data?.data as IOrder;

  const isLoading = useMemo<boolean>(
    () => isLoadingFetch || isFetching || isRefetching || isInitialLoading,
    [isLoadingFetch, isFetching, isRefetching, isInitialLoading]
  );

  return (
    <>
      <Space
        direction="vertical"
        size="middle"
        style={{
          width: '100%',
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} md={24} lg={24} xl={8}>
            <Space
              direction="vertical"
              size="middle"
              style={{
                width: '100%',
              }}
            >
              <OrderInfo
                order={order}
                refetch={refetch}
                isLoading={isLoading}
              />
              {order && <OrderNotes order={order} />}
              <Invoice
                invoice={order?.invoice}
                serviceType={order?.serviceType}
                logisticType={order?.logisticType}
                refetch={refetch}
              />
            </Space>
          </Col>
          <Col xs={24} md={24} lg={24} xl={16}>
            <Space
              direction="vertical"
              size="middle"
              style={{
                width: '100%',
              }}
            >
              {order && <OrderPurchaser order={order} />}
              {order && <OrderLogistic order={order} refetch={refetch} />}
              {order && <OrderShippingAddressEdit orderId={order.id} />}
            </Space>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <OrderProducts
              order={order}
              refetch={refetch}
              isLoading={isLoading}
            />
          </Col>
          <Col xs={24}>
            <InvoiceLines invoice={order?.invoice} />
          </Col>
          <Col xs={24}>
            <Payments invoice={order?.invoice} refetch={refetch} />
          </Col>
        </Row>
      </Space>
    </>
  );
};
